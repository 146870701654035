



















import '@/assets/styles/admin.scss';
import { mapGetters } from 'vuex';
import { Header, Navigation, SubHeader, Footer } from '../components/admin';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'DefaultLayout',
  components: {
    Header,
    Navigation,
    SubHeader,
    Footer,
  },
})
export default class DefaultLayout extends Vue {
  showMenu = false;
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
